import { Link } from "@remix-run/react";
import { Logo } from "./logo";
import { HamburgerMenuIcon } from "@radix-ui/react-icons";
import { Button } from "./ui/button";
import React from "react";

export function Header(props: { children: React.ReactNode }) {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <header className="bg-[hsl(var(--primary))] text-background">
      <div className="mx-auto hidden max-w-screen-lg items-center justify-between gap-2 px-2 py-2 md:flex">
        <Link to="/">
          <Logo />
        </Link>
        {props.children}
      </div>
      <div className="flex items-center justify-between px-4 py-2 md:hidden">
        <Link to="/">
          <Logo />
        </Link>
        <Button
          onClick={() => setIsOpen((open) => !open)}
          variant="outline"
          className="text-foreground"
          size="icon"
        >
          <HamburgerMenuIcon className="size-4" />
        </Button>
      </div>
      {isOpen && (
        <div className="flex flex-col py-2 md:hidden">{props.children}</div>
      )}
    </header>
  );
}
