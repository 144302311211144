import { ChevronDown, Mail, Phone } from "lucide-react";
import { Logo } from "./logo";
import { Fragment } from "react";
import { Link } from "@remix-run/react";
import stateConverter from "us-state-converter";

const StateNames = stateConverter();

export function FooterCities() {
  return (
    <div className="mx-auto w-full max-w-screen-lg space-y-4 px-10">
      <details className="border-b">
        <summary className="flex flex-1 cursor-pointer items-center justify-between space-x-4 py-4 text-left font-medium transition-all hover:underline md:text-lg [&[data-state=open]>svg]:rotate-180">
          All States
          <ChevronDown
            width="16px"
            height="16px"
            className="h-4 w-4 shrink-0 text-muted-foreground transition-transform duration-200"
          />
        </summary>
        <ul className="grid grid-flow-row grid-cols-3 gap-2 py-2 pb-4 text-left sm:grid-cols-5">
          {Object.entries(StateNames)
            .slice(0, 100)
            .map(([_, { name, usps: abbr }]) => (
              <li key={abbr} className="text-sm">
                <Link
                  className="hover:font-bold hover:underline"
                  to={`/state/${abbr.toLowerCase()}`.toLowerCase()}
                  title={name}
                >
                  {name}
                </Link>{" "}
              </li>
            ))}
        </ul>
      </details>
      <span className="justify-left flex flex-wrap items-center gap-2 text-left text-sm">
        <b className="font-semibold">Top states</b>
        {Object.entries(StateNames)
          .slice(0, 12)
          .map(([_, { name, usps: abbr }]) => (
            <Fragment key={abbr}>
              <span>|</span>
              <Link
                className="font-normal hover:font-bold hover:underline"
                to={`/state/${abbr.toLowerCase()}`.toLowerCase()}
                title={name}
              >
                {name}
              </Link>
            </Fragment>
          ))}
      </span>
    </div>
  );
}

export function Footer() {
  return (
    <footer className="mt-8 bg-primary pt-8 text-white">
      <nav className="mx-auto flex max-w-screen-lg flex-col items-center justify-between px-2 md:flex-row md:px-10">
        <Link className="block py-4 md:py-0" to="/">
          <Logo />
        </Link>
        <div className="flex items-center">
          <Link
            className="cursor-pointer border-r px-2 text-center text-sm hover:underline"
            to="/privacy"
          >
            Privacy Policy
          </Link>
          <Link
            className="cursor-pointer border-r px-2 text-center text-sm hover:underline"
            to="/terms"
          >
            Terms of Service
          </Link>
          <Link
            className="cursor-pointer border-r px-2 text-center text-sm hover:underline"
            to="https://airtable.com/appIHktZDbHeSWXZw/pagUF8VrWYvxOkBEN/form"
          >
            Claim Your Business
          </Link>
          <Link
            className="cursor-pointer px-2 text-center text-sm hover:underline"
            to="/all-moving-companies"
          >
            All Companies
          </Link>
        </div>
      </nav>
      <div className="border-t-0.5 border-secondary mt-8 space-y-8 bg-black pb-8 pt-2 text-center md:mt-12">
        <FooterCities />
        <div className="mx-auto flex max-w-screen-lg flex-col items-center gap-4 break-words px-8 md:flex-row-reverse md:items-start md:justify-between md:gap-2">
          <span>
            © Copyright Handbook Enterprises{" "}
            <span className="block sm:inline">2024 All Rights Reserved</span>
          </span>
          <span>
            <details className="text-center md:text-left">
              <summary className="cursor-pointer hover:underline">
                Partner Sites
              </summary>
              <ul className="grid grid-cols-1 items-center gap-2 pl-3 pt-2 text-sm md:items-start">
                <li>
                  <a
                    href="https://www.garagedoorhandbook.com"
                    className="hover:underline"
                  >
                    Garage Door Handbook
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.speedytreeremoval.com"
                    className="hover:underline"
                  >
                    Speedy Tree Removal
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.candidmoving.com"
                    className="hover:underline"
                  >
                    Candid Moving
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.wisconsingaragedoorpro.com"
                    className="hover:underline"
                  >
                    Garage Door Pro
                  </a>
                </li>
              </ul>
            </details>
          </span>
        </div>
      </div>
    </footer>
  );
}
