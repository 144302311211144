import { captureRemixErrorBoundaryError } from "@sentry/remix";
import {
  json,
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
} from "@remix-run/react";
import type { LinksFunction, LoaderFunctionArgs } from "@remix-run/node";
import remixImageStyles from "remix-image/remix-image.css?url";
import { Partytown } from "@builder.io/partytown/react";
import "./tailwind.css";
import { Nav } from "./components/nav";
import { Header } from "./components/header";
import { Logo } from "./components/logo";
import { Search } from "./components/search";
import { Footer } from "./components/footer";
import { Suspense } from "react";

export const links: LinksFunction = () => [
  { rel: "preconnect", href: "https://c.s-microsoft.com" },
  {
    rel: "preconnect",
    href: "https://c.s-microsoft.com/static",
    crossOrigin: "anonymous",
  },
  { rel: "stylesheet", href: remixImageStyles },
];

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#002c6a" />
        <meta name="msapplication-TileColor" content="#002c6a" />
        <meta name="theme-color" content="#002c6a" />

        <Meta />
        <Links />
      </head>
      <body suppressHydrationWarning>
          <Header>
            <div className="flex flex-col items-start justify-end gap-2 px-2 text-foreground md:flex-row md:items-center md:px-0">
              <Nav />
              <Search />
            </div>
          </Header>
          <div className="bg-background py-4">
            <main className="mx-auto max-w-screen-lg px-4 py-4 dark:bg-black">
              {children}
            </main>
          </div>
          <Footer />
          <ScrollRestoration />
          <Scripts />
          {process.env.NODE_ENV !== "development" && (
            <Suspense>
              <script
                async
                defer
                suppressHydrationWarning
                dangerouslySetInnerHTML={{
                  __html: `(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
		})(window, document, "clarity", "script", "obxau2p6m4");`,
                }}
              />
              <script
                async
                suppressHydrationWarning
                src="https://www.googletagmanager.com/gtag/js?id=G-P5KNX04YMT"
              ></script>
              <script
                async
                dangerouslySetInnerHTML={{
                  __html: `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

	gtag('config', 'G-P5KNX04YMT');`,
                }}
              />
            </Suspense>
          )}
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <div>Something went wrong</div>;
};

export default function App() {
  return <Outlet />;
}
