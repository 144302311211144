import { SearchIcon } from "lucide-react";
import { Input, inputClasses } from "./ui/input";
import { cn } from "~/lib/utils";
import { useEffect, useRef, useState } from "react";
import {
  Form,
  useFetcher,
  useLoaderData,
  useLocation,
  useMatches,
  useNavigate,
  useNavigation,
  useSearchParams,
  useSubmit,
} from "@remix-run/react";
import debounce from "lodash/debounce";

export function Search() {
  let { q } = useLoaderData<{ q?: string }>() ?? {};
  let [searchParams, _] = useSearchParams();
  if (searchParams.has("q")) {
    q = searchParams.get("q") ?? q;
  }
  const submit = useSubmit();
  const [hasFocus, setHasFocus] = useState(false);
  const location = useLocation();
  const isSearch = location.pathname === "/search";
  const navigate = useNavigate();
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    const searchField = ref.current;
    if (searchField instanceof HTMLInputElement) {
      searchField.value = q || "";
    }
  }, [q]);
  return (
    <>
      <Form
        action="/search"
        onChange={(e) => {
          const isFirstSearch = q === null;
          submit(e.currentTarget, {
            replace: !isFirstSearch,
          });
        }}
        role="search"
        className={cn(inputClasses, "relative bg-white")}
      >
        <div className="absolute inset-y-0 left-2 flex items-center justify-center">
          <SearchIcon className="size-4" />
        </div>
        <input
          ref={ref}
          name="q"
          defaultValue={q}
          onFocus={() => setHasFocus(true)}
          onBlur={(e) => {
            setHasFocus(false);
            // if we are on the search page, go back
            // also dont trigger this if we lose focus to the searchpage
            if (isSearch && e.relatedTarget === null) {
              {
                /* navigate(-1); */
              }
            }
          }}
          className={cn(`ml-5 w-60 outline-none`, hasFocus && "md:w-96")}
          placeholder="Type here to search for a company"
        />
      </Form>
      {/* {hasFocus && !q && <SearchPage />} */}
    </>
  );
}

export function SearchPage() {
  return (
    <div className="fixed inset-x-0 top-12 z-[2] min-h-full bg-white">
      <div className="mx-auto max-w-screen-lg px-2">
        <span className="text-center">Type to search</span>
      </div>
    </div>
  );
}
